
import FEATURES from '@/modules/common/constants/features.constant';
import { Component, Vue } from 'vue-property-decorator';
import CompsetDocumentFilter from '@/modules/document-filters/components/compset-type-document-filter.vue';
import RankingProviders from '@/modules/ranking/components/ranking-providers.vue';
import ToUppercase from '@/modules/common/filters/to-uppercase.filter';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Inject } from 'inversify-props';

@Component({
    components: {
        CompsetDocumentFilter,
        RankingProviders,
        Tooltip,
    },
    filters: { ToUppercase },
})
export default class GuestReviewsContainer extends Vue {
    @Inject(UserServiceS) protected userService!: UserService;

    get reviewsRoute() {
        const { userService } = this;

        return {
            name: `${userService.viewAs}.ranking`,
            params: {
                hotelId: userService.currentHotelId,
                userType: userService.viewAs,
            },
        };
    }

    get ranking() {
        return FEATURES.GUEST_REVIEW;
    }
}
