
import { Inject } from 'inversify-props';
import { Component, Prop, Vue } from 'vue-property-decorator';
import type Day from '@/modules/common/types/day.type';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import Calendar from '@/modules/common/components/ui-kit/calendar.vue';
import EventTypePoint from '@/modules/common/components/ui-kit/event-type-point.vue';
import isYearType from '@/modules/common/component-validators/is-year.validator';
import isMonthType from '@/modules/common/component-validators/is-month.validator';

@Component({
    components: { Calendar, EventTypePoint },
    filters: {
        FirstLetter: ((value:string) => value.charAt(0)),
    },
})
export default class EventsSimpleCalendar extends Vue {
    @Inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({
        type: Number,
        required: true,
        validator: isYearType,
    })
    public year!: Year;

    @Prop({
        type: Number,
        required: true,
        validator: isMonthType,
    })
    public month!: Month;

    get title(): string {
        const date = new Date(this.year, this.month);
        const monthName = date.toLocaleDateString('en-US', { month: 'long' });
        return [monthName, date.getFullYear()].join(' ');
    }

    hasHolidayEvents(day: Day) : boolean {
        const { year, month } = this;

        return this.eventsManagerService.hasDateHolidayEvents(new Date(year, month, day));
        // return Boolean(this.eventsManagerService.getCountryEventsByDay({ day, month: this.month, year: this.year }).length);
    }

    hasLocalEvents(day: Day): boolean {
        const { year, month } = this;
        return this.eventsManagerService.hasDateOtherEvents(new Date(year, month, day));
    }
}
