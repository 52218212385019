
import { Component, Vue } from 'vue-property-decorator';
import {
    ChartData,
    ChartOptions,
} from 'chart.js';
import { Inject } from 'inversify-props';
import ScoreGraphLabels from '@/modules/home/components/score/score-graph-labels.vue';
import ComparedScoreDocumentFilter from '@/modules/home/components/score/compared-score-document-filter.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import HotelHomepageService, { HotelHomepageServiceS } from '@/modules/home/march-demo/hotel-homepage.service';
import ScoreService, { ScoreServiceS } from '@/modules/score/score.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import METRICS_SCORE from '@/modules/score/constants/metrics-score.constant';
import { $enum } from 'ts-enum-util';
import ScoreGraphTooltip from '@/modules/home/components/score/score-graph-tooltip.vue';

@Component({
    components: {
        CustomGraph,
        ComparedScoreDocumentFilter,
        ScoreGraphLabels,
        LoaderWrapper,
        ScoreGraphTooltip,
    },
})
export default class ScoreContainer extends Vue {
    @Inject(HotelHomepageServiceS) protected hotelHomepageService!: HotelHomepageService;
    @Inject(ScoreServiceS) scoreService!: ScoreService;
    @Inject(UserSettingsS) private userSettings!: UserSettingsService;

    activeMetric: METRICS_SCORE = METRICS_SCORE.MONTH;

    get metrics() {
        return $enum(METRICS_SCORE).map(value => ({
            value,
            name: this.$t(`score.metricsScore.${value}`) as string,
        }));
    }

    changeMetric(metric: METRICS_SCORE) {
        this.activeMetric = metric;
    }

    get skeleton() {
        return this.scoreService.storeState.loading.isLoading();
    }

    get comparedTo() {
        return this.$t(`score.comparedToSetting.${this.userSettings.storeState.comparedTo}`) as string;
    }

    get chartData(): ChartData {
        if (!this.scoreService.scoreHistory) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const arr: {date: Date, value: number | null}[] = [];

        const dataMax = this.scoreService.createDataMax();
        Object.assign(dataMax, this.scoreService.scoreHistory);

        Object.entries(dataMax).forEach(([key, val]) => {
            arr.push({ date: new Date(key), value: this.scoreService.roundScoreData(val) });
        });

        const newArray = this.scoreService.changeMetrics(arr, this.activeMetric);

        const historyData: (number | null)[] = [];
        const historyDateLabels: Date[] = [];

        const sortedHistory = newArray.sort((a, b) => Number(a.date) - Number(b.date));
        sortedHistory.forEach((element: any) => {
            historyData.push(element.value);
            historyDateLabels.push(element.date);
        });

        const CIScoreData = { data: historyData, labels: historyDateLabels };

        return {
            labels: CIScoreData.labels,
            datasets: [
                {
                    data: CIScoreData.data,
                    backgroundColor: '#2081AC',
                    borderColor: '#2081AC',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                    fill: false,
                    label: 'My Score',
                },
                {
                    data: this.scoreService.switchComparedToGraphs(this.userSettings.storeState.comparedTo, this.activeMetric),
                    fill: 'origin',
                    backgroundColor: '#79CFF3',
                    borderDash: [0, 1],
                    lineTension: 0,
                    borderJoinStyle: 'round',
                    label: this.comparedTo,
                },
            ],
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 0,
                    hoverRadius: 4,
                },
            },
            hover: {
                intersect: false,
            },
            // @ts-ignore
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                        maxTicksLimit: 10,
                        autoSkip: true,
                        // eslint-disable-next-line consistent-return
                        callback: (value, index, values) => {
                            const date = new Date(value);
                            const day = date.getDate();
                            const month = date.getMonth();
                            const year = date.getFullYear().toString().substr(-2);
                            return `${day}/${month + 1}/${year}`;
                        },
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        maxTicksLimit: 5,
                        autoSkip: true,
                        stepSize: 1,
                        min: 0,
                        max: 100,
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }
}
