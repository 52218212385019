import { render, staticRenderFns } from "./demand-graph-component.vue?vue&type=template&id=b3183bfc&scoped=true&"
import script from "./demand-graph-component.vue?vue&type=script&lang=ts&"
export * from "./demand-graph-component.vue?vue&type=script&lang=ts&"
import style0 from "./demand-graph-component.vue?vue&type=style&index=0&id=b3183bfc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3183bfc",
  null
  
)

export default component.exports